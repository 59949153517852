let callbackUrl
if (process.env.NODE_ENV === 'production') {
    callbackUrl = 'https://staging.gmp.webranking.biz/callback'
} else {
    callbackUrl = 'http://localhost:3000/callback'
}

export const AUTH_CONFIG = {
    domain: 'webranking.eu.auth0.com',
    clientID: 'HeYSz9R0IiY94-z52BzvVePyBWqRvbiL',
    callbackUrl,
    apiUrl: 'https://dash.webranking.tools/'
}