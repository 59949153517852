import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import React, { Component } from 'react'
import { api } from '../utils/index'
import PageTitle from './PageTitle'
import Loading from './Loading'
import { toast } from 'react-toastify'

class EditCreateClient extends Component {
  constructor(props) {
    super(props)
    this.create = this.props.match.params.clientSlug === 'create'
    this.create
      ? (this.state = {
          revenueModels: [],
          views: [
            {
              type: 'open',
              partnerIds: '',
              advertiserIds: '',
              w_fee_fixed_rate: 15
            },
            {
              type: 'guaranteed',
              partnerIds: '',
              advertiserIds: '',
              w_fee_fixed_rate: 4
            }
          ]
        })
      : (this.state = {
          client: undefined,
          // clientHistory: undefined,
          // currentRevenueModel: undefined,
          revenueModels: []
          //views: []
        })
  }

  componentDidMount() {
    this.revenueModels = api.get('revenue-models/').then(data => {
      this.setState({ revenueModels: data.data })
    })
    console.log(this.create)
    if (!this.create) {
      this.client = api
        .get('clients/', {
          params: { client: this.props.match.params.clientSlug }
        })
        .then(data => {
          //multiply per 100 vat rate and views w_fee_ixed_rate
          data.data[0].clienthistory.vat_rate *= 100
          // data.data[0].views = data.data[0].views.map((view) => ({
          //   ...view,
          //   w_fee_fixed_rate: view['w_fee_fixed_rate'] * 100,
          //
          // }))
          data.data[0].views = data.data[0].views.map(view => {
            let v = { ...view }
            v['w_fee_fixed_rate'] = view['w_fee_fixed_rate'] * 100
            if (
              view.extra_data !== undefined &&
              view.extra_data.dv360_partner_ids !== undefined
            ) {
              if (
                typeof view.extra_data.dv360_partner_ids === 'string' ||
                view.extra_data.dv360_partner_ids instanceof String
              )
                v['partnerIds'] = view.extra_data.dv360_partner_ids
              else v['partnerIds'] = view.extra_data.dv360_partner_ids.join(',') // from list to string comma separated
            }
            if (
              view.extra_data !== undefined &&
              view.extra_data.dv360_advertiser_ids !== undefined &&
              view.extra_data.dv360_advertiser_ids !== null
            ) {
              if (
                typeof view.extra_data.dv360_advertiser_ids === 'string' ||
                view.extra_data.dv360_advertiser_ids instanceof String
              )
                v['advertiserIds'] = view.extra_data.dv360_advertiser_ids
              else
                v['advertiserIds'] = view.extra_data.dv360_advertiser_ids.join(
                  ','
                ) // from list to string comma separated
            }
            if (
              view.extra_data !== undefined &&
              view.extra_data.filter_ads_type !== undefined
            )
              v['type'] = view.extra_data.filter_ads_type
            return v
          })
          this.setState({ client: data.data[0] })
        })
    }
  }

  render() {
    console.log(this.state)
    const addClientSchema = Yup.object().shape({
      name: Yup.string().required('This field is required!'),
      revenueModel: Yup.number().required('This field is required!'),
      trustFund: Yup.number().required('This field is required!'),
      vatRate: Yup.number().required('This field is required!'),
      views: Yup.array()
    })
    return (
      <div className="AddNewClient text-left">
        <div className="w-container">
          <PageTitle
            title={this.create ? 'Create New Client' : 'Edit Client'}
          />
        </div>
        {!this.create &&
        (this.state.client === undefined ||
          this.state.client.clienthistory === undefined) ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              name: this.create ? '' : this.state.client.name,
              revenueModel: this.create
                ? ''
                : this.state.client.clienthistory.revenue_model,
              trustFund: this.create
                ? 0
                : this.state.client.clienthistory.trust_fund,
              vatRate: this.create
                ? 22
                : this.state.client.clienthistory.vat_rate,
              views: this.create
                ? [
                    {
                      type: 'open',
                      partnerIds: '',
                      advertiserIds: '',
                      w_fee_fixed_rate: 15
                    },
                    {
                      type: 'guaranteed',
                      partnerIds: '',
                      advertiserIds: '',
                      w_fee_fixed_rate: 4
                    },
                    {
                      type: 'open-youtube',
                      partnerIds: '',
                      advertiserIds: '',
                      w_fee_fixed_rate: 4
                    },
                    {
                      type: 'guaranteed-youtube',
                      partnerIds: '',
                      advertiserIds: '',
                      w_fee_fixed_rate: 4
                    }
                  ]
                : this.state.client.views
            }}
            onSubmit={values => {
              this.create
                ? api
                    .post('clients/', {
                      name: values.name,
                      revenue_model_id: values.revenueModel,
                      trust_fund: values.trustFund,
                      vat_rate: values.vatRate,
                      dv360_views: values.views
                    })
                    .then(data => {
                      let props = this.props
                      props.history.replace('/')
                      toast.success('Client Created successfully')
                    })
                    .catch(error => {
                      toast.error(
                        'Error Creating client, ' + error.response.data.message
                      )
                    })
                : api
                    .post('clients/', {
                      client_id: this.state.client.id,
                      name: values.name,
                      revenue_model_id: values.revenueModel,
                      trust_fund: values.trustFund,
                      vat_rate: values.vatRate,
                      dv360_views: values.views
                    })
                    .then(data => {
                      let props = this.props
                      props.history.replace('/' + this.state.client.slug)
                      toast.success('Client Edited successfully')
                    })
                    .catch(error => {
                      toast.error(
                        'Error Creating client, ' + error.response.data.message
                      )
                    })
            }}
            validationSchema={addClientSchema}
            validateOnBlur={true}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <div className="w-bg-grey py-4">
                <div className="w-container">
                  <form className="w-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">Client Name</label>
                      <Field id="name" name="name" />
                      {errors.name && touched.name && (
                        <div className="input-feedback">{errors.name}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="revenueModel">Revenue model</label>
                      <select
                        id="revenueModel"
                        value={values.revenueModel}
                        onChange={handleChange}
                        className={
                          errors.revenueModel && touched.revenueModel
                            ? 'form-control error'
                            : 'form-control'
                        }
                      >
                        {this.create && (
                          <option> -- select a revenue model --</option>
                        )}
                        {Object.values(this.state.revenueModels).map(
                          (revenueModel, index) => (
                            <option
                              key={revenueModel.id}
                              value={revenueModel.id}
                              label={revenueModel.name}
                            />
                          )
                        )}
                      </select>
                      {errors.revenueModel && touched.revenueModel && (
                        <div className="input-feedback">
                          {errors.revenueModel}
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-md-8">
                        <div className="form-group">
                          <label htmlFor="trustFund">Trust Fund</label>
                          <Field
                            id="trustFund"
                            name="trustFund"
                            type="number"
                          />
                          {errors.trustFund && touched.trustFund && (
                            <div className="input-feedback">
                              {errors.trustFund}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-4">
                        <div className="form-group">
                          <label htmlFor="vatRate">VAT Rate</label>
                          <div className="w-form-element-um">
                            <Field
                              id="vatRate"
                              name="vatRate"
                              type="number"
                              step="0.01"
                              min="0"
                              max="100"
                            />
                            <span>%</span>
                          </div>
                        </div>
                      </div>
                      {errors.vatRate && touched.vatRate && (
                        <div className="input-feedback">{errors.vatRate}</div>
                      )}
                    </div>

                    <div className="col-xs-12">
                      <small>
                        Insert multiple IDs separated by "," <br />
                        Leave Advertiser IDs empty to get every partner's
                        advertiser
                      </small>
                    </div>

                    <FieldArray
                      name="views"
                      render={arrayHelpers => (
                        <div className="form-group">
                          {values.views &&
                            values.views.length > 0 &&
                            values.views.map((view, index) => (
                              <div className="w-form-row" key={index}>
                                <div className="row">
                                  <div className="col-xs-12 col-md-3">
                                    <div className="w-form-row-element">
                                      <label htmlFor={'type-' + index}>
                                        Type
                                      </label>
                                      <Field
                                        component="select"
                                        name={`views.${index}.type`}
                                      >
                                        <option value="open" label="Open" />
                                        <option
                                          value="guaranteed"
                                          label="Guaranteed"
                                        />
                                        <option
                                          value="open-youtube"
                                          label="Open Youtube"
                                        />
                                        <option
                                          value="guaranteed-youtube"
                                          label="Guaranteed Youtube"
                                        />
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-3">
                                    <div className="w-form-row-element">
                                      <label htmlFor={'partnerIds-' + index}>
                                        Partner IDs
                                      </label>
                                      <Field
                                        id={'name-' + index}
                                        name={`views.${index}.partnerIds`}
                                        // disabled={view.id !== undefined}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-3">
                                    <div className="w-form-row-element">
                                      <label htmlFor={'advertiserIds-' + index}>
                                        Avertiser IDs
                                      </label>
                                      <Field
                                        id={'name-' + index}
                                        name={`views.${index}.advertiserIds`}
                                        // disabled={view.id !== undefined}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-2">
                                    <div className="w-form-row-element">
                                      <label
                                        htmlFor={'w_fee_fixed_rate-' + index}
                                      >
                                        W Fee
                                      </label>
                                      <div className="w-form-element-um">
                                        <Field
                                          id={'name-' + index}
                                          name={`views.${index}.w_fee_fixed_rate`}
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          max="100"
                                          // disabled={view.id !== undefined}
                                        />
                                        <span>%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {((view.id === undefined && !this.create) ||
                                  (this.create && values.views.length > 1)) && ( // Only showing the "remove" button from the second view, to keep forced the first
                                  <button
                                    className="w-remove-button"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove view from list
                                  >
                                    <img src="/images/svg/close.svg" />
                                  </button>
                                )}
                              </div>
                            ))}
                          {/*<button*/}
                          {/*  type="button"*/}
                          {/*  className="w-button"*/}
                          {/*  onClick={() =>*/}
                          {/*    arrayHelpers.push({*/}
                          {/*      type: 'open',*/}
                          {/*      partnerIds: '',*/}
                          {/*      advertiserIds: '',*/}
                          {/*      w_fee_fixed_rate: '15'*/}
                          {/*    })*/}
                          {/*  }*/}
                          {/*>*/}
                          {/*  Add View*/}
                          {/*</button>*/}
                        </div>
                      )}
                    />

                    <div className="col-xs-12">
                      <small>
                        For Open/Guaranteed and Youtube splits we look at
                        "Inventory Commitment" and "Line Item Type"
                        {/*Guaranteed means taking only data with a 'Platform Fee*/}
                        {/*Rate' between 3.1 and 3.3 (limits excluded). Open is*/}
                        {/*everything else*/}
                      </small>
                    </div>

                    <div className="w-submit-wrap">
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          />
        )}
      </div>
    )
  }
}

export default EditCreateClient
